<template>
    <v-toolbar
        tag="div"
        height="48"
        :style="style"
    >
        <v-container
            v-if="membershipObj?.entity"
            class="max-container"
            fluid
        >
            <div
                class="d-flex align-center flex-grow-0"
            >
                <v-avatar
                    v-if="membershipObj.entity.brand?.primaryLogo?.url"
                    color="white"
                    size="36"
                    class="mr-4"
                    rounded
                >
                    <img
                        :aspect-ratio="membershipObj.entity.brand?.primaryLogo.aspectRatio"
                        :src="membershipObj.entity.brand?.primaryLogo.url"
                        alt="Organization Logo"
                    />
                </v-avatar>

                <!-- Membership Name -->
                <h2
                    class="flex-grow-1 text-truncate text-h6"
                    :title="membershipObj.entity.name"
                >
                    {{ membershipObj.entity.name }}
                </h2>

                <!-- Quick Links -->
                <div
                    v-if="canUseBulkOrder"
                    class="d-flex text-body-2 ml-4 align-center flex-column"
                >
                    <v-btn
                        to="/account/bulk-order"
                        color="on-secondary"
                        variant="tonal"
                        size="small"
                        nuxt
                    >
                        Start New Order
                    </v-btn>
                </div>
                <div
                    v-if="membershipObj?.entity?.isRetail"
                    class="d-flex text-body-2 ml-4 align-center flex-column"
                >
                    <v-btn
                        to="/account"
                        color="on-secondary"
                        variant="tonal"
                        size="small"
                        nuxt
                    >
                        Wholesale Portal
                    </v-btn>
                </div>
                <div
                    v-if="canManageTeam"
                    class="d-flex text-body-2 ml-4 align-center flex-column"
                >
                    <v-btn
                        to="/account/manage-members"
                        color="on-secondary"
                        variant="tonal"
                        size="small"
                        nuxt
                    >
                        Manage Team
                    </v-btn>
                </div>

                <!-- Member Desc/Role -->
                <div
                    class="d-flex text-body-2 ml-4 align-center flex-column"
                >
                    <div>{{ membershipObj.entity.descriptor }}</div>
                    <v-chip
                        v-if="membershipObj?.role?.name"
                        size="x-small"
                    >
                        {{ membershipObj?.role.name }}
                    </v-chip>
                </div>
            </div>
        </v-container>
    </v-toolbar>
</template>

<script lang="ts" setup>
    import {
        useCurrentTheme,
        useUserLite
    } from '~/composables';
    import type { Membership } from '~/types';
    import { mdiAccountGroup } from '@mdi/js';

    const props = defineProps<{
        membershipObj: Membership;
    }>();

    const useCurrentThemeObj = useCurrentTheme();
    const { isOrgMemberAllowed } = useUserLite();
    
    const style = computed<Undefinable<Record<string, string>>>(() => {
        if (!props.membershipObj?.entity?.brand) {
            return;
        }

        let { primaryColor, textColor } = props.membershipObj.entity?.brand;
        let color: string, backgroundColor: string;

        if (useCurrentThemeObj.themeIsDark.value) {
            color = textColor || 'rgb(var(--v-theme-accent-lighten-2))';
            backgroundColor = primaryColor || 'rgb(var(--v-theme-accent-darken-2))';
        } else {
            color = textColor || 'rgb(var(--v-theme-accent-darken-2))';
            backgroundColor = primaryColor || 'rgb(var(--v-theme-accent-lighten-2))';
        }

        return {
            color,
            backgroundColor
        };
    });

    const canManageTeam = computed<boolean>(() => isOrgMemberAllowed('resource', 'members', ['view']));
    const canUseBulkOrder = computed<boolean>(() => isOrgMemberAllowed('resource', 'orders', ['add']));
</script>

<style lang="scss" scoped>
.v-avatar {
    img {
        width: 80%;
        height: auto;
    }
}
</style>
